<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <b-field grouped class="column-direction-touch">
      <b-field class="has-text-centered" label="Suspensão Automática de Pregões">
        <b-switch size="is-medium" v-model="data.automaticSuspension" :disabled="disableFields"
          >{{ data.automaticSuspension ? 'Sim' : 'Nunca' }}
        </b-switch>
      </b-field>

      <b-field
        label="Selecione o tempo"
        v-if="data.automaticSuspension"
        :type="campoInvalido('waitingTime', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('waitingTime', 'data') ? 'Insira um valor' : ''"
      >
        <b-clockpicker
          editable
          maxlength="5"
          placeholder="Intervalo permitido: 00:10 - 23:59"
          ref="clockpicker"
          v-mascara:hora
          v-model="$v.data.waitingTime.$model"
          :disabled="disableFields"
          :hour-format="'24'"
          :hours-label="'Horas'"
          :minutes-label="'Min'"
          :max-time="maximumTime"
          :min-time="minimumTime"
          @input="validInsertedTime()"
        >
          <button class="button is-danger" @click="data.waitingTime = null">
            <b-icon icon="close"></b-icon>
            <span>Limpar</span>
          </button>
        </b-clockpicker>
      </b-field>
      <b-field label="Integração Contábil">
        <b-select expanded placeholder="Integração Contábil" v-model="data.accountIntegration">
          <option
            v-for="option in accountIntegrationOptions"
            :key="option.id"
            :value="option.value"
            >{{ option.name }}</option
          >
        </b-select>
      </b-field>
    </b-field>

    <b-field>
      <button
        class="button is-primary is-fullwidth save-button"
        type="submit"
        :class="{ ' is-loading': loading.save }"
        :disabled="disableFields"
        @click.prevent.stop="saveBuyerConfigurations()"
      >
        Salvar
      </button>
    </b-field>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import campoMixin from '@/mixins/formulario';
import { accountIntegrationOptions } from '@/utils/data';

export default {
  name: 'BuyerConfigurations',
  mixins: [campoMixin],
  data() {
    const data = {
      automaticSuspension: false,
      waitingTime: null,
      accountIntegration: null,
    };

    const loading = {
      general: false,
      save: false,
    };

    return {
      accountIntegrationOptions,
      data,
      loading,
    };
  },
  validations: {
    data: {
      waitingTime: {
        required: requiredIf(function verifyAutomaticSuspension() {
          return this.data.automaticSuspension;
        }),
      },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      logged: 'getLogged',
      users: 'getCompanyUsers',
      user: 'getUser',
    }),
    ...mapGetters('companiesConfigurations', {
      configurations: 'getConfigurations',
    }),
    disableFields() {
      return (
        !this.logged ||
        !this.users.some(user => user.id === this.user.id && user.permissions.editCompany)
      );
    },
    maximumTime() {
      const date = new Date();
      date.setHours(23);
      date.setMinutes(59);

      return date;
    },
    minimumTime() {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(10);

      return date;
    },
  },
  methods: {
    ...mapActions(['getUsers']),
    ...mapActions('companiesConfigurations', ['saveConfigurations', 'searchConfigurations']),
    closeClockPicker() {
      if (this.$refs.clockpicker) {
        this.$refs.clockpicker.close();
      }
    },
    convertDateToMinutes(date) {
      return date.getHours() * 60 + date.getMinutes();
    },
    convertMinutesToDate(minutes) {
      const date = new Date();
      const hours = Math.floor(minutes / 60);
      const min = minutes % 60;
      date.setHours(hours);
      date.setMinutes(min);

      return date;
    },
    loadConfigurations() {
      if (this.configurations.companyId) {
        this.data.accountIntegration = this.configurations.accountIntegration;
        if (this.configurations.automaticSuspension) {
          this.data.automaticSuspension = true;
          this.data.waitingTime = this.convertMinutesToDate(
            this.configurations.automaticSuspension,
          );
        }
      }
    },
    async saveBuyerConfigurations() {
      if (this.data.automaticSuspension) {
        if (!this.validar('data')) {
          return;
        }
        if (!this.validInsertedTime()) {
          return;
        }
      }

      try {
        await this.saveConfigurations({
          companyId: this.company.id,
          accountIntegration: this.data.accountIntegration,
          automaticSuspension: this.data.automaticSuspension
            ? this.convertDateToMinutes(this.data.waitingTime)
            : null,
        });
        this.$alerta('Configurações salvas', 'is-success');
        this.closeClockPicker();
      } catch (error) {
        this.$alerta('Erro ao salvar configurações', 'is-danger');
      }
    },
    validInsertedTime() {
      this.closeClockPicker();
      const minutes = this.convertDateToMinutes(this.data.waitingTime);
      if (minutes < 10 || minutes > 1440) {
        this.$alerta('Intervalo permitido: 00:10 - 23:59', 'is-danger');
        return false;
      }
      return true;
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await Promise.all([
        this.getUsers(this.$route.params.id),
        this.searchConfigurations({ companyId: this.$route.params.id }),
      ]);
      await this.loadConfigurations();
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>
